import React from 'react';
import ReactPlayer from 'react-player';
import VooPlayer from '../players/voo_player';
import WhistPlayer from '../players/whist_player';
import BunnyPlayer from '../players/bunny_player'; // Import BunnyPlayer
import BunnyPlayerQuiz from '../players/bunny_player_quiz'; // Import BunnyPlayer
import VimeoPlayer from '../players/VimeoPlayer';
import {
  getVooPlayerId,
  isVooPlayer,
  VOO_PLAYER_VIDEO_PREFIX_URL,
  setWistiaDimensions,
  setVooPlayerSize,
  getWhistPlayerId,
  isWhistPlayer,
  WHIST_PLAYER_VIDEO_PREFIX_URL,
  getVimeoUrl,
  getBunnyPlayerId,
  isBunnyPlayer,
} from './video_helper';
import { getQueryParameterByName } from '../../../../lib/helpers';

// added custom player
ReactPlayer.addCustomPlayer(VooPlayer);
ReactPlayer.addCustomPlayer(WhistPlayer);
ReactPlayer.addCustomPlayer(BunnyPlayerQuiz);

export function getPlayer(videoType, videoId, videoUrl, videoProps, isInlinePlaybackEnabled = 0) {
  if (videoType === Providers.YOUTUBE) {
    return <ReactPlayer controls={true} url={`https://www.youtube.com/watch?v=${videoId}`} {...videoProps} />;
  } else if (videoType === Providers.VIMEO) {
    return <VimeoPlayer ref={videoProps.ref} url={`${getVimeoUrl(videoUrl, videoId)}`} {...videoProps} playsinline={isInlinePlaybackEnabled} />; // Pass playsinline prop
  } else if (videoType === Providers.WISTIA) {
    return <ReactPlayer controls={true} url={`https://fast.wistia.com/medias/${videoId}`} {...videoProps} config={{ wistia: { options: {  } } }}/>;
  }  else if (videoType === Providers.VOO_PLAYER) {
    return <ReactPlayer controls={true} url={`${VOO_PLAYER_VIDEO_PREFIX_URL}${videoId}`} {...videoProps} config={{ playerId: videoId }} />;
  } else if (videoType === Providers.WHIST_PLAYER) {
    return <ReactPlayer controls={true} url={`${WHIST_PLAYER_VIDEO_PREFIX_URL}${videoId}`} {...videoProps}
                        config={{playerId: videoId}}/>;
  } else if (videoType === Providers.BUNNY_PLAYER) {
      return <BunnyPlayer url={`${videoUrl}`} {...videoProps} />;
  } else {
    return <p>Unsupported video type: {videoType}</p>;
  }
}

export function getVideoProps(videoUrl) {
  const isYoutube = videoUrl.match('https://www.youtube.com/watch\\?v=([^&]*)') ||
    videoUrl.match('https://youtu.be/([-\\w]+)');
  if (isYoutube) {
    return { videoType: Providers.YOUTUBE, videoId: isYoutube[1] };
  }

  const isVimeo = videoUrl.match('https://vimeo.com/(\\d+)') ||
    videoUrl.match('https://player.vimeo.com/video/(\\d+)');
  if (isVimeo) {
    return { videoType: Providers.VIMEO, videoId: isVimeo[1] };
  }

  const isWistia = videoUrl.match('https?:\/\/[^.]+\.(wistia\.com|wi\.st)\/(medias|embed)\/([^&]*)')

  if (isWistia) {
    return { videoType: Providers.WISTIA, videoId: isWistia[3] };
  }

  if (isVooPlayer(videoUrl)) {
    return { videoType: Providers.VOO_PLAYER, videoId: getVooPlayerId(videoUrl) };
  }

  if (isWhistPlayer(videoUrl)) {
    return { videoType: Providers.WHIST_PLAYER, videoId: getWhistPlayerId(videoUrl) };
  }

  if (isBunnyPlayer(videoUrl)) {
    return { videoType: Providers.BUNNY_PLAYER, videoId: getBunnyPlayerId(videoUrl)[0], libraryId: getBunnyPlayerId(videoUrl)[1] };
  }

  return { videoType: Providers.UNKNOWN, videoId: '' };
}

export function calcVideoUrl(videoType, videoId, videoUrl) {
  if (videoType === Providers.YOUTUBE) {
    return `https://www.youtube.com/watch?v=${videoId}`;
  }
  if (videoType === Providers.VIMEO) {
    return getVimeoUrl(videoUrl, videoId);
  }
  if (videoType === Providers.WISTIA) {
    return `https://home.wistia.com/medias/${videoId}`;
  }
  if (videoType === Providers.VOO_PLAYER) {
    return `${VOO_PLAYER_VIDEO_PREFIX_URL}${videoId}`;
  }
  if (videoType === Providers.WHIST_PLAYER) {
    return `${WHIST_PLAYER_VIDEO_PREFIX_URL}${videoId}`;
  }
  return '';
}

export function getVimeoUrl(videoUrl, videoId) {
  return `https://player.vimeo.com/video/${videoId}`;
}

export const Providers = {
  YOUTUBE: 'youtube',
  WISTIA: 'wistia',
  VIMEO: 'vimeo',
  VOO_PLAYER: 'vooplayer',
  WHIST_PLAYER: 'whistplayer',
  BUNNY_PLAYER: 'bunnyplayer',
  UNKNOWN: 'unknown',
};

export const isPlayerShallFixDimensions = (type) => {
  return [Providers.WISTIA, Providers.VOO_PLAYER].includes(type);
}
export const fixPlayerDimensions = (videoContainer, videoId, type) => {
  if (videoContainer) {
    const clientHeight = videoContainer.clientHeight;
    const clientWidth = videoContainer.clientWidth;
    if (Providers.WISTIA === type) {
      const wistiaEmbedContainer = videoContainer.querySelector('.wistia_embed');
      if (wistiaEmbedContainer) {
        //reset wistia container width for resolve mobile  issue
        Object.assign(wistiaEmbedContainer.parentNode.style, { width: ''})
        setWistiaDimensions(wistiaEmbedContainer.wistiaApi, videoContainer, videoId, clientWidth, clientHeight)
      }
    } else if (Providers.VOO_PLAYER === type) {
      setVooPlayerSize(videoContainer.style, clientWidth, clientHeight)
    }
  }
}
